import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import LocalizedLink from "components/localizedLink";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import Layout from "components/layout";
import PostHeader from "components/PostHeader";
import { mq } from "utils/helper";
import { css } from "@emotion/react";
import useTranslations from "hooks/useTranslations";
import "style/pagination.css";
import "style/filter-bar.css";
import { contentDecode } from "utils/content-decode";
import TableOfContents from "components/TableOfContents";
import SEO from "components/seo";

const BlogPostTemplate = ({ pageContext, location, data }) => {
  const post = data.mdx;
  const arPost = post.localizations?.data?.map((a) => a.attributes)[0];
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  let content = post?.content?.data?.content;
  content = locale === "en" ? content : arPost?.content;
  const metadata = { title: isRTL ? arPost?.title : post.title };
  if (typeof window != "undefined") {
    window.scroll(0, 0);
  }
  const firstBox = css({
    ".image_resized": {
      height: "auto"
    },
    "ul:first-of-type": {
      border: `6px solid ${colors.dark}`,
      boxShadow: "-5px 5px 12px 0 rgba(0,0,0,0.25)",
      padding: `${rhythm(1)} ${rhythm(2)}`,
      "& li": {
        listStyle: "none",
        margin: 0,
        "&::before": {
          content: '"•"',
          color: colors.primary,
          display: "inline-block",
          width: "0.8em",
          fontSize: "2em",
        },
      },
      [mq[0]]: {
        paddingLeft: rhythm(1),
        paddingRight: rhythm(1),
        marginLeft: 0,
      },
    },
  });
  const [isMobile, setIsMobile] = useState(false);

  const isPress = post?.type === "press";
  const isMethod = post?.type === "methodology";

  return (
    <div>
      <Layout>
        <SEO metadata={metadata} />
        <PostHeader post={data.mdx} arPost={arPost} location={location} />
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "25% 50% 25%",
            paddingTop: rhythm(2),
            direction: isRTL ? "rtl" : "ltr",
            [mq[2]]: {
              display: "block",
              paddingTop: 0,
              marginRight: rhythm(1.3),
              marginLeft: rhythm(1.3),
            },
          }}
        >
          {isMobile || (
            <TableOfContents post={data.mdx} arPost={arPost} tr={tr} />
          )}
          <div
            css={[
              {
                maxWidth: rhythm(32.25),
                marginBottom: rhythm(4),
                justifySelf: "center",
              },
              isPress || isMethod || firstBox,
            ]}
          >
            {contentDecode(content)}
          </div>
          {isMobile || <div></div>}
        </div>
      </Layout>
    </div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query TemplateMethodTool($slug: String!, $title: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx: strapiInvestigation(slug: { eq: $slug }) {
      id
      content {
        data {
          content
        }
      }
      slug
      locale
      title
      date
      type
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      desc
      dataset
      localizations {
        data {
          attributes {
            locale
            title
            date
            type
            desc
            dataset
            content
          }
        }
      }
    }
    allmdx: allStrapiInvestigation(filter: { title: { eq: $title } }) {
      edges {
        node {
          slug
          locale
          title
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250, placeholder: BLURRED)
              }
            }
          }
          localizations {
            data {
              attributes {
                locale
                title
              }
            }
          }
        }
      }
    }
  }
`;
